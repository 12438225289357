.line {
    margin: 10px;
    width: 100%;
    height: 20px;
    border-bottom: 1px solid #e5ded9;
    text-align: center;
}

.lineText {
    font-size: 20px;
    color: #e5ded9;
    padding: 0 10px;
}