.accessElement {
    padding: 10px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    font-size: 20px;
    background-color: #fff;
    border: 1px solid #e5ded9;
    color: #666;
    font-family: "NN-Dagny-Display", Calibri, Corbel, Verdana, sans-serif;
    display: block;
    width: 80%;
}

.accessElement:hover {
    background-color: #fff;
    border: 1px solid #ee7f00;
    color: #ee7f00;
    cursor: pointer;
}

.title {
    color: #ee7f00;
}

.selectButton {
    font-size: 30px;
    padding: 10px;
    margin: 10px;
}

.styled.button {
    background: orange;
}

.filterBox {
    width: 80%;
    height: 55px;
    margin: 10px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ee7f00;
}

.pagination-container {
    justify-content: center;
}

.copy-image {
    margin: 8px
}

.copy-image:hover {
    border: 1px solid #ee7f00;
    margin: 8px
}
